import { Account } from "../../types";

export const UPDATE_ACCOUNT = 'ACCOUNT/UPDATE_ACCOUNT';
export const REGISTER_LOADING = 'ACCOUNT/REGISTER_LOADING';
export const REGISTER_SUCCESS = 'ACCOUNT/REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'ACCOUNT/REGISTER_FAILED';
export const VIEW_ACCOUNT = 'ACCOUNT/VIEW_ACCOUNT';

export interface AccountDispatchTypes {
  type: string;
  payload?: any;
}